// import bootstrap stuff
@import "./bootstrap/bootstrap-reboot";
@import "./bootstrap/bootstrap-grid";
@import "./bootstrap/bootstrap-utilities";
@import "./bootstrap/_forms";
@import "./bootstrap/_buttons";

// vars
$bodyFont: "Jost", sans-serif;
$titleFont: "Kanit", sans-serif;
$bodyFontWeight: 500;

$yellow: #E5C607;
$purple: #7F96FF;

body {
  background-color: black;
  background-image: url('../images/paper-bg.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 567px auto;
  color: white;
  font-family: $bodyFont;
  font-weight: $bodyFontWeight;
  font-size: 16px;
  padding-top: 133px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  &.btn-primary {
    background-color: $yellow;
    border-color: $yellow;
    color: black;

    &:hover {
      background-color: lighten($yellow, 15%);
      border-color: lighten($yellow, 15%);
    }
  }
}

.header {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  padding: 45px 0;
  transition: background-color 100ms;

  &.scroll {
    background-color: black;
  }

  img {
    width: 121px;
    height: auto;
  }

  .col {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:nth-child(2) {
      justify-content: flex-end;
    }
  }

  nav {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        padding: 0 25px 0 0;

        &:last-child {
          padding-right: 0;
        }

        a {
          font-weight: 700;
          color: white;
          letter-spacing: -0.29px;
          line-height: calc(35/16);
          text-decoration: none;

          &.active,
          &:hover {
            position: relative;

            &::before {
              content: "";
              display: block;
              position: absolute;
              top: calc(100% + 2px);
              left: 0;
              width: 100%;
              height: 3px;
              background-color: $yellow;
            }
          }
        }
      }
    }
  }
}

.hero {
  text-align: center;
  padding-bottom: 50px;

  h1 {
    font-size: 6.25rem;
    font-family: $titleFont;
    font-weight: 800;
    letter-spacing: -1.82px;
    line-height: 1;
    margin: 0 0 35px;

    > span {
      color: $yellow;
      display: inline-block;
      position: relative;

      > span {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: "housing crisis!";
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        position: absolute;
        top: 5px;
        left: -3px;
        color: transparent;
        z-index: 1;
      }
    }
  }

  p {
    font-size: 1.25rem;
    margin: 0;
  }
}

.content {
  .path-container {
    position: relative;
    display: inline-flex;

    svg {
      &.play {
        path:not(#dashed) {
          animation: dash 1s linear forwards;
        }

        circle {
          opacity: 1;
        }
      }

      path:not(#dashed) {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
      }

      circle {
        transition: opacity 250ms;
        opacity: 0;

        &:last-child {
          transition-delay: 750ms; 
        }
      }
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }

  .path-col {
    position: relative;
  }

  .text-box {
    position: absolute;
  }

  .gif {
    position: absolute;

    > img.tape.top {
      position: absolute;
      top: -10px;
      left: calc(50% - 36.5px);
      width: 73px;
      height: auto;
    }

    > img.tape.top-right {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 79px;
      height: auto;
    }

    > img.tape.top-left {
      position: absolute;
      top: -30px;
      left: -30px;
      width: 79px;
      height: auto;
    }

    > img.tape.bottom-right {
      position: absolute;
      bottom: -30px;
      right: -30px;
      width: 68px;
      height: auto;
    }

    > img.tape.bottom-left {
      position: absolute;
      bottom: -30px;
      left: -30px;
      width: 68px;
      height: auto;
    }
  }

  .emojis {
    font-size: 2.8125rem;
    letter-spacing: -0.82px;
    line-height: calc(40/45);
    display: flex;
    position: absolute;

    span {
      &:not(:last-child) {
        padding-right: 2rem;
      }
    }
  }

  .text-box {
    border-radius: 4.2px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid white;
    padding: 4px 8px;

    span {
      font-size: 1.125rem;
      letter-spacing: -0.33px;
      line-height: calc(42/18);
    }
  }

  .text-row {
    position: relative;

    .text-col {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .gif-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .big {
    font-size: 2.1875rem;
    font-family: $titleFont;
    font-weight: 500;
    letter-spacing: 0.64px;
    line-height: calc(45/35);
    margin: 0;

    span {
      font-weight: 800;
      color: $purple;
    }
  }

  .big + .big {
    margin-top: 1.5rem;
  }

  .regular {
    font-size: 1.25rem;
    line-height: calc(35/20);
    letter-spacing: -0.36px;
  }

  .big + .regular {
    margin-top: 1.5rem;
  }

  .extra-big {
    font-size: 4.0625rem;
    font-weight: 800;
    font-family: $titleFont;
    line-height: calc(70/65);
    letter-spacing: -1.18px;

    > span {
      position: relative;
      display: inline-block;

      &::before {
        content: var(--text);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        position: absolute;
        top: 5px;
        left: -3px;
        color: transparent;
        z-index: 1;        
      }

      span {
        color: $yellow;
        position: relative;
        z-index: 2;
      }
    }
  }

  .extra-big + .extra-big {
    margin-top: 2rem;
  }

  .path-1 {
    .path-container {
      margin-left: 17.5%;

      .star {
        position: absolute;
        top: -16.5px;
        right: 1px;
      }
    }

    .text-box {
      &.box-1 {        
        right: 7.5%;
        top: 1rem;
      }

      &.box-2 {
        left: 15%;
        top: 20%;
      }
    }

    .gif {
      right: 15%;
      bottom: 10%;

      > img:not(.tape) {
        width: 249px;
        height: auto;
      }
    }
  }

  .path-2 {
    margin-top: -100px;

    .path-container {
      margin-left: 28%;
    }

    .emojis {
      bottom: -18px;
      left: 110%;
    }

    .gif {
      top: 25%;
      right: 20%;

      > img:not(.tape) {
        width: 193px;
        height: auto;
      }
    }
  }

  .text-2 {
    padding-top: 55px;

    .text-col {
      align-items: flex-start;
    }

    .gif {
      position: relative;
      margin-top: 59px;

      > img:not(.tape) {
        width: 292px;
        height: auto;
      }
    }
  }

  .path-3 {
    .path-container {
      margin-left: 30%;
    }

    .emojis {
      &.emojis-1 {
        top: -18px;
        left: 110%;
      }

      &.emojis-2 {
        top: 100%;
        right: 110%;
      }
    }

    .gif {
      top: 80%;
      right: 10%;

      > img:not(.tape) {
        width: 190px;
        height: auto;
      }
    }
  }

  .text-3 {
    padding-top: 100px;
  }

  .path-4 {
    padding-top: 60px;

    .path-container {
      margin-left: 20%;
    }

    .emojis {
      bottom: -10px;
      left: 15%;
    }

    .gif {
      &.gif-1 {
        top: 10%;
        left: 0%;

        > img:not(.tape) {
          width: 179px;
          height: auto;
        }
      }

      &.gif-2 {
        top: 50%;
        right: 10%;

        > img:not(.tape) {
          width: 190px;
          height: auto;
        }
      }
    }
  }

  .text-4 {
    padding-top: 50px;
  }

  .path-5 {
    padding-top: 60px;

    .path-container {
      margin-left: 40%;
    }

    .emojis {
      bottom: -10px;
      right: 115%;
    }

    .gif {
      &.gif-1 {
        top: 15%;
        left: 10%;

        > img:not(.tape) {
          width: 190px;
          height: auto;
        }
      }

      &.gif-2 {
        bottom: -10%;
        right: 10%;

        > img:not(.tape) {
          width: 191px;
          height: auto;
        }
      }
    }
  }

  .text-5 {
    padding-top: 50px;
  }

  .path-6 {
    padding-top: 60px;

    .path-container {
      margin-left: 15%;
    }

    .emojis {
      bottom: -10px;
      left: 15%;
    }

    .gif {
      &.gif-1 {
        top: 15%;
        right: 25%;

        > img:not(.tape) {
          width: 172px;
          height: auto;
        }
      }

      &.gif-2 {
        bottom: 5%;
        right: 5%;

        > img:not(.tape) {
          width: 188px;
          height: auto;
        }
      }
    }
  }

  .text-6 {
    padding-top: 50px;
  }

  .path-7 {
    padding-top: 60px;

    .path-container {
      margin-left: 15%;
    }

    .emojis {
      bottom: -10px;
      left: 10%;
    }

    .gif {      
      bottom: 85%;
      left: 5%;

      > img:not(.tape) {
        width: 187px;
        height: auto;
      }
    }
  }

  .text-7 {
    padding-top: 50px;
  }

  .path-8 {
    padding-top: 60px;

    .path-container {
      margin-left: 30%;
    }

    .emojis {
      bottom: -10px;
      left: 15%;
    }

    .gif {
      &.gif-1 {
        bottom: 100%;
        right: 10%;

        > img:not(.tape) {
          width: 194px;
          height: auto;
        }
      }

      &.gif-2 {
        top: 80%;
        right: 5%;

        > img:not(.tape) {
          width: 295px;
          height: auto;
        }
      }
    }
  }

  .text-8 {
    padding-top: 50px;
  }

  .path-9 {
    padding-top: 60px;

    .path-container {
      margin-left: 42.5%;
    }

    .gif {      
      left: 10%;
      top: 20%;

      > img:not(.tape) {
        width: 194px;
        height: auto;
      }
    }
  }

  .text-9 {
    margin-top: -50px;
  }

  .path-10 {
    padding-top: 60px;

    .path-container {
      margin-left: 15%;
    }

    .emojis {
      bottom: -10px;
      left: 15%;
    }

    .gif {      
      bottom: 65%;
      left: 10%;

      > img:not(.tape) {
        width: 293px;
        height: auto;
      }
    }
  }

  .text-10 {
    padding-top: 60px;
  }

  .path-11 {
    padding-top: 60px;

    .path-container {
      margin-left: 20%;
    }

    .emojis {
      bottom: -10px;
      right: 15%;
    }

    .gif {      
      bottom: 50%;
      right: 10%;

      > img:not(.tape) {
        width: 194px;
        height: auto;
      }
    }
  }

  .text-11 {
    padding-top: 60px;
  }

  .path-12 {
    padding-top: 10px;

    .path-container {
      margin-left: 25%;
    }

    .gif {      
      bottom: 90%;
      left: 10%;

      > img:not(.tape) {
        width: 190px;
        height: auto;
      }
    }
  }

  .text-12 {
    text-align: center;

    .gif {
      top: 30%;
      right: 5%;
      width: auto;

      > img:not(.tape) {
        width: 145px;
        height: auto;
      }
    }

    .emojis {
      width: auto;

      &.emojis-1 {
        top: 15%;
        right: 15%;
      }

      &.emojis-2 {
        bottom: 30%;
        left: 10%;
      }
    }
  }

  .path-13 {
    padding-top: 10px;
    text-align: center;
  }

  .text-13 {
    text-align: center;
    padding-top: 20px;
  }

  .counter {
    h3 {
      font-size: 1.875rem;
      letter-spacing: -0.55px;
      line-height: calc(35/30);
      font-weight: 700;
      text-align: center;
      max-width: 537px;
      margin: 0 auto 44px;

      span {
        color: $yellow;
      }
    }

    .progress {
      width: 100%;
      max-width: 612px;
      margin: 0 auto;
      height: 12px;
      border-radius: 9px;
      border: 1px solid white;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        width: 40%;
        height: 100%;
        border-radius: inherit;
        background-color: $yellow;
      }
    }
  }
}

.subscribe {
  padding-bottom: 128px;

  .top {
    background-image: url('../images/subscribe-top-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 70px 75px;
    min-height: 450px;
    position: relative;
    margin-top: 80px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 300px;
      left: 0;
      right: 0;
      background-color: #1C1C1C;
    }

    .big-tape {
      position: absolute;
      top: -20px;
      height: auto;

      &.top-left {
        left: -30px;
        width: 135px;
      }

      &.top-right {
        right: -30px;
        width: 151px;
      }
    }

    h2 {
      text-align: center;
      font-family: $titleFont;
      font-weight: 500;
      font-size: 1.75rem;
      letter-spacing: -0.64px;
      line-height: calc(45/35);
      margin: 0 0 50px;

      span {
        color: $yellow;
      }
    }

    p.quote {
      font-style: italic;
      font-weight: 400;
      font-size: 1.25rem;
      letter-spacing: -0.36px;
      line-height: calc(30/20);
    }
  }

  form {
    padding-top: 2rem;

    label {
      color: $yellow;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: -0.25px;
      line-height: calc(35/14);
    }

    .form-control {
      background-color: transparent;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: white;
      border-radius: 0;
      padding: 0.75rem 0;
      font-size: 1.25rem;
      margin-bottom: 1rem;

      &::placeholder {
        color: white;
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked + label[for="sign"] {
      span {
        svg {
          display: block;
        }
      }
    }

    label[for="sign"] {
      color: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 1.5rem;

      span {
        display: inline-flex;
        width: 25px;
        height: 25px;
        border: 2px solid white;
        margin-right: 28px;
        align-items: center;
        justify-content: center;

        svg {
          display: none;

          path {
            fill: $yellow;
          }
        }
      }
    }

    button.btn.btn-primary {
      width: 317px;
      max-width: 100%;
      font-weight: 500;
      font-size: 1.25rem;
      letter-spacing: -0.36px;
      line-height: calc(35/20);
    }
  }
}

.demand-action {
  padding-top: 100px;

  .intro {
    h1 {
      font-family: $titleFont;
      font-weight: 500;
      text-align: center;
      font-size: 2.1875rem;
      letter-spacing: -0.64px;
      line-height: calc(45/35);
      margin: 0 0 63px;
    }

    p {
      font-size: 1.25rem;
      line-height: calc(35/20);
      letter-spacing: -0.36px;
      margin: 0 0 50px;
    }
  }
}

.faq {
  padding-top: 100px;
  
  h1 {
    font-family: $titleFont;
    font-weight: 500;
    text-align: center;
    font-size: 2.1875rem;
    letter-spacing: -0.64px;
    line-height: calc(45/35);
    margin: 0 0 72px;
  }

  .categories {
    .category {
      border-radius: 5px;
      border: 1px solid white;
      padding: 25px 50px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &.open {
        .btn {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='16px' height='3px' viewBox='0 0 16 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='FAQ' transform='translate(-1056.000000, -379.000000)' fill='%23FFFFFF'%3E%3Cg id='Group-10' transform='translate(310.000000, 333.000000)'%3E%3Cg id='Actions-/-Navigation-/-subtract-/-32' transform='translate(738.000000, 31.500000)'%3E%3Crect id='Fill' x='8' y='15' width='16' height='2'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }

        .questions {
          max-height: 2000px;
        }
      }

      .btn {
        font-size: 1.25rem;
        font-family: $titleFont;
        font-weight: 500;
        line-height: calc(35/20);
        position: relative;
        letter-spacing: -0.36px;
        margin: 0;
        padding: 0;
        border: none;
        color: white;
        display: block;
        width: 100%;
        text-align: left;

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::after {
          content: "";
          position: absolute;
          top: calc(50% - 16px);
          right: 0;
          display: block;
          width: 32px;
          height: 32px;
          background-image: url("data:image/svg+xml,%3Csvg width='16px' height='17px' viewBox='0 0 16 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='FAQ' transform='translate(-1056.000000, -860.000000)' fill='%23FFFFFF'%3E%3Cg id='Group-9' transform='translate(310.000000, 820.000000)'%3E%3Cg id='Actions-/-Navigation-/-add-/-32' transform='translate(738.000000, 32.500000)'%3E%3Cpolygon id='Fill' points='17 15 17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      .questions {
        transition: max-height 500ms;
        max-height: 0;
        overflow: hidden;

        .question {
          font-size: 0.875rem;
          letter-spacing: -0.25px;

          &:first-child {
            padding-top: 41px;
          }

          h3 {
            color: $yellow;
            font-size: inherit;
            line-height: calc(35/14);
            font-weight: 700;
            margin: 0 0 5px;
          }

          .answer {
            line-height: calc(24/14);
          }
        }
      }
    }
  }
}

footer.footer {
  margin-top: 130px;
  padding-bottom: 50px;
  font-size: 0.875rem;
  letter-spacing: -0.25px;
  line-height: calc(35/14);

  .footer-col {
    border-top: 1px solid #222222;
    padding-top: 48px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 184px;
      height: auto;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $yellow;
      margin-top: 1rem;
      font-weight: 500;

      img {
        margin: 0 7px 0 0;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  p {
    margin: 40px 0 0;
  }

  ul.social {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    list-style: none;
    margin: 31px 0 0;
    padding: 0;

    li {
      &:not(:last-child) {
        padding-right: 20px;
      }
    }
  }
}
